import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import ApartmentIcon from '@material-ui/icons/Apartment';
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import PregnantWomanIcon from '@material-ui/icons/PregnantWoman';// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>About the project</h2>
          <h5 className={classes.description}>
          Part of our project to empower and assist women in the Middle East, which is a social responsibility to All able people all over the world . 
A large portion of the profits from the books projects that I publish will be returned 
As small loans between (500 and 1000) Euros as money to help women living in refugee camps in Turkey, Lebanon, Jordan to start their businesses (such as sewing, making and selling some simple foods,
And when women start making profits, they repay the loans in small installments without any Loan interest  , transferring this money again to another woman to complete her own project, and so on.
Cooperation will be undertaken with one of the large charitable organizations working to assist refugees in refugee camps to coordinate this project.

          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Assist Women"
              description="If they are in need. Better child support for women who struggle because they have to do everything on their own."
              icon={PregnantWomanIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Independence"
              description="Fair paid jobs for women. No more forced marriages. Greater punishments for men who dominate their wifes. Abused women should easily find a save place to take refuge where they are protected."
              icon={ApartmentIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Project completion"
              description="With women more supported the society will be more balanced and flourish more."
              icon={DoneAllIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
